import styles from "./Footer.module.css";


const Footer = () => {
  return (
   <footer className={styles.footer}>
    <h3>Projeto do Aluno Matheus Dev. Em React Java e Spring Boot!</h3>
    <p>Portifolio Web &copy; 2024</p>
   
   </footer>
  );
};

export default Footer;